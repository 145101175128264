<template>
  <component :is="layout">
    <div v-if="Users" class="card p-2 m-2 text-center ">
      <h3 class="text-center">Users and Panels</h3>
      <div
        v-for="User in Users"
        :key="User.id"
        class="py-2 d-flex justify-content-between"
      >
        <h5>{{ User.name }}</h5>
        <button v-if="User.table_time == null" @click="showTable(User)" class="btn  btn-warning bg-warning">
          Add Table Game
        </button>
        <button v-else @click="select_table_time(User.table_time)" class="btn btn-primary bg-primary m-1">{{ User.table_time.game_name }}</button>
      </div>
    </div>

    <div v-if="current_table_time" class="card p-2 m-2 text-center ">
        <div class="d-flex p-2 justify-content-between">
            <h3>{{ current_table_time.game_name }}</h3>
            <button class="btn btn-success bg-success" @click="addTableGame()">Add Panel</button>
        </div>
        <table class="table border  table-responsive w-100">
            <thead>
                <th>Date</th>
                
                 <th>Actions</th>
            </thead>
            <tbody v-if="current_table_games">
                <tr v-for="(panel,index) in current_table_games.data" :key="panel.id">
                    <td>{{ formatDate(panel.created_at) }}</td>
              
                    <td>
                        <button @click="show_table_game(index)" class="btn btn-primary bg-primary m-1">Edit</button>
                        <button @click="delete_table_game(panel.id)" class="btn btn-danger bg-danger m-1">Delete</button></td>
                </tr>
            </tbody>
        </table>
       <pagination class="" :per-page="10" v-model="page" :records="current_table_games.total" @paginate="fetch_table_game"/>
    </div>
    <Modal :showModal="showTableModal">
      <template v-slot:header>
        <h3>{{ user.name }}</h3>
      </template>
      <template v-slot:body>
          <span v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <p class="alert-danger" v-for="error in errors" :key="error">
              {{ error }}
            </p>
          </span>
        <div class="form-group">
            <label for="">Game Name</label>
            <input placeholder="Game Name" required
          class="m-1 rounded form-group" v-model="game_name" />
        </div>
        <label for="">Game Times</label>
         <div class="row">
             <div v-for="(input,index) in inputs"
          :key="input" class="col-6"><input
          size="8"
          v-model="Times[index]"
          placeholder="time here"
          class="m-1"
          type="text"
        /></div>
         </div>

        <div class="d-flex">
          <button @click="addInputs()" class="btn btn-success bg-success">+</button>
          <button @click="inputs--" class="btn btn-danger bg-danger">-</button>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="createTableTimeTable()" class="btn btn-success bg-success modal-default-button">Update</button>
        <button
          class="btn btn-danger bg-danger modal-default-button"
          @click="showTableModal = false"
        >
          close
        </button>
      </template>
    </Modal>

    <!-- edit table game panel -->
    <Modal :showModal="show_edit_table">
         <template v-slot:header>
            <h3>{{ current_table_time.game_name }}</h3>
         </template>
         <template v-slot:body>
          <div class="row justify-content-around">
              <input v-if="current_table_time.one" placeholder="game 1" class="col-6 p-1" size="10" v-model="current_table_game.one" type="text">
              <input v-if="current_table_time.two"  placeholder="game 2" class="col-6 p-1" size="10" v-model="current_table_game.two" type="text">
              <input v-if="current_table_time.three"  placeholder="game 3" class="col-6 p-1" size="10" v-model="current_table_game.three" type="text">
              <input v-if="current_table_time.four"  placeholder="game 4" class="col-6 p-1" size="10" v-model="current_table_game.four" type="text">
              <input v-if="current_table_time.five"  placeholder="game 5" class="col-6 p-1" size="10" v-model="current_table_game.five" type="text">
              <input v-if="current_table_time.six"  placeholder="game 6" class="col-6 p-1" size="10" v-model="current_table_game.six" type="text">
              <input v-if="current_table_time.seven"  placeholder="game 7" class="col-6 p-1" size="10" v-model="current_table_game.seven" type="text">
              <input v-if="current_table_time.eight"  placeholder="game 8" class="col-6 p-1" size="10" v-model="current_table_game.eight" type="text">
              <input  v-if="current_table_time.nine" placeholder="game 9" class="col-6 p-1" size="10" v-model="current_table_game.nine" type="text">
              <input  v-if="current_table_time.ten" placeholder="game 10" class="col-6 p-1" size="10" v-model="current_table_game.ten" type="text">
              <input  v-if="current_table_time.eleven" placeholder="game 11" class="col-6 p-1" size="10" v-model="current_table_game.eleven" type="text">
              <input  v-if="current_table_time.twelve" placeholder="game 12" class="col-6 p-1" size="10" v-model="current_table_game.twelve" type="text">
              <input  v-if="current_table_time.thirteen" placeholder="game 13" class="col-6 p-1" size="10" v-model="current_table_game.thirteen" type="text">
              <input  v-if="current_table_time.fourteen" placeholder="game 14" class="col-6 p-1" size="10" v-model="current_table_game.fourteen" type="text">
          </div>
         </template>
         <template v-slot:footer>
             <button @click="update_table_game()" class="btn btn-success bg-success modal-default-button">Update</button>
        <button
          class="btn btn-danger bg-danger modal-default-button"
          @click="show_edit_table = false"
        >
          close
        </button>
         </template>
    </Modal>
  </component>
</template>

<script>

import Modal from "../Modal.vue";
import Pagination from 'vue-pagination-2';
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';

export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  components: {
    Modal,
    Pagination
  },
  data() {
    return {
      Users: null,
      user: null,
      showTableModal: false,
      inputs: 1,
      Times:[],
      game_name:null,
      page:1,
      errors:[],
      current_table_time:null,
      current_table_games:null,
      current_table_game:null,
      show_edit_table:false,
    };
  },
  mounted() {
    this.fetchTableGames();
  },
  methods: {
    fetchTableGames() {
      axiosObject.get("/api/admin/table_time",axiosConfig)
        .then(
          function (response) {
            this.Users = response.data.users;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    showTable(user) {
      this.showTableModal = true;
      this.user = user;
    },
    createTableTimeTable() {
      this.errors = [];
      if (!this.game_name || !this.Times[0]) {
        this.errors.push("Game Name and at least one game is required.");
        return;
      }
      axiosObject.post("/api/admin/table_time",
         {'game_name':this.game_name,
         'times':this.Times,
         'user':this.user},axiosConfig)
        .then(
          function (response) {
            this.fetchTableGames()
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    addInputs()
    {
        if(this.inputs<14)
          {this.inputs++}
    },
    select_table_time(table_time)
    {
        this.current_table_time = table_time
        this.fetch_table_game()
    },
    addTableGame(){
     const id = this.current_table_time.id;
     axiosObject.post("/api/admin/table_game",{'id':id},axiosConfig)
     .then(function(response){
         this.fetch_table_game()
     }.bind(this)).catch(function(error){
        alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
     })
    },
    fetch_table_game(page=1)
    {
        this.page = page
        const id = this.current_table_time.id;
        axiosObject.get('/api/admin/table_game?table_time_id='+id+
    '&page='+page,axiosConfig)
        .then(function(response){
           this.current_table_games = response.data.table_games
           
        }.bind(this)).catch(function (error) {
           alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
        })
    },
    formatDate(date)
    {
        return new Date(date).toLocaleDateString("hi-IN")
    },
    show_table_game(index)
    {
       this.current_table_game = this.current_table_games.data[index]
       this.show_edit_table = true
    },
    update_table_game()
    {
       axiosObject.patch('/api/admin/table_game/'+this.current_table_game.id,this.current_table_game,axiosConfig)
       .then(function(response){
           console.log(response.data)
           this.show_edit_table =false
       }.bind(this)).catch(function(error){
          alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
       })
    },
    delete_table_game(id)
    {
      axiosObject.delete('/api/admin/table_game/'+id,axiosConfig).then(function(response){
       this.fetch_table_game(this.page)
      }.bind(this)).catch(function(error){
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
      })
    }
  },
};
</script>

<style>

</style>
